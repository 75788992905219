import { ethers } from "ethers";
Vue.component("MetamaskButton", {
    props: {
        challenge: null,
    },
    data() {
        return {};
    },
    methods: {
        async login() {
            if (typeof window.ethereum === "undefined") {
                // TODO: improve error message . Instead, redirect on Metamask
                alert("MetaMask not detected. Please try again from a MetaMask enabled browser.");
                return;
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const address = await window.ethereum.request({ method: "eth_requestAccounts" });
            const signer = provider.getSigner();
            const message = [
                "Wait a minute ! Who are you ?!",
                "I wish to connect you to Metadventure by your wallet.",
                "Please sign me in!",
            ].join("\n");
            const signature = await signer.signMessage(message);
            return axios
                .post("/web3/login", { message, address: address[0], signature, challenge: this.challenge })
                .then((res) => {
                    if (!res.data) {
                        Bus.$emit("openToast", {
                            title: "Error",
                            message: `Error while performing Web3 login.`,
                            variant: "danger",
                            autoHideDelay: 5000,
                        });
                        throw Error("data is null");
                    }
                    //alert('Connection successful');
                    if (res.data.redirectUrl) {
                        window.location = res.data.redirectUrl;
                    } else {
                        window.location = "/game";
                    }
                })
                .catch((err) => {
                    // FIXME: openToast does not work
                    console.error(err);
                    Bus.$emit("openToast", {
                        title: "Error",
                        message: `Error while performing Web3 login.`,
                        variant: "danger",
                        autoHideDelay: 5000,
                    });
                    throw err;
                });
            return false;
        },
    },
});
